import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../axios'; 

// Fetch vendors from API
export const fetchVendors = createAsyncThunk(
    'vendor/fetchVendors',
    async () => {
      const response = await API.post('/vendor/map');  // Assuming this is your API endpoint for vendors
      return response.data;
    }
);

export const updateVendor = createAsyncThunk(
    'vendor/updateVendor',
    async ({ vendorName, additionalData }) => {
        const response = await API.post('/vendor/add', { vendorName, additionalData });
        return { vendorName, additionalData };  // Return the updated vendor data
    }
);

const vendorSlice = createSlice({
    name: 'vendor',
    initialState: {
      data: [],
      status: 'idle', 
      error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchVendors.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(fetchVendors.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.data = action.payload.mappedVendors;
        })
        .addCase(fetchVendors.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        })
        // Handle vendor update
        .addCase(updateVendor.fulfilled, (state, action) => {
          const { vendorName, additionalData } = action.payload;
          const existingVendor = state.data.find(vendor => vendor.name === vendorName);
          if (existingVendor) {
            existingVendor.additionalData = additionalData;
          }
        });
    },
});

export default vendorSlice.reducer;
