import React, { useEffect, useState } from 'react';
import Hydra from '../components/layout/HydraLayout';
import { fetchJobs, toggleNewJobDialog } from '../features/job/jobSlice';
import { fetchCollections } from '../features/shopify/collections';
import { fetchQueue } from '../features/shopify/queue';
import { useDispatch, useSelector } from 'react-redux';
import SingleJob from './../components/job/SingleJob';
import NewJob from './../components/job/NewJob';
import Vendors from './../components/vendor/Vendors';

const HomePage = () => {

    const [open, setOpen] = useState(false);
 
    const dispatch = useDispatch();
    const jobs = useSelector((state) => state.job.data);
    const status = useSelector((state) => state.job.status);
    const queue = useSelector((state) => state.queue.data);
    const isNewJobDialogOpen = useSelector((state) => state.job.isNewJobDialogOpen);


    const collections = useSelector((state) => state.shopify.data);
    


    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchJobs());
            dispatch(fetchCollections());
            dispatch(fetchQueue());
        }
    }, [status, dispatch]);

    return (
        <>
            <Hydra>
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="text-xl font-semibold text-gray-900">Activities</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                A list of all the all jobs runnign in backend
                            </p>
                        </div>
                        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                            <button
                                onClick={() => dispatch(toggleNewJobDialog())}
                                type="button"
                                className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                            >
                                Add New Job
                            </button>
                        </div>
                    </div>
                    <div className="mt-8 flex flex-col">
                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-300">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                    Title
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    Scrapping Link#
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    Categories
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    Current Page
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    Interval
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    Last Page
                                                </th>
                                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                    <span className="sr-only">Edit</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                            {jobs && jobs.map((job, index) => (
                                                <SingleJob job={job} key={index} />
                                            ))}

                                        </tbody>
                                    </table>

                                    {status === 'loading' && 'Loading...'}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <NewJob collections={collections} open={isNewJobDialogOpen} setOpen={() => dispatch(toggleNewJobDialog())} />
                <div className="mt-20"></div>
                <center><h2 id="queue">Products In Queue: <span id="product-count">{queue}</span></h2></center>
                <div className="mt-20"></div>

                <div className="px-4 sm:px-6 lg:px-8">
                  <Vendors />
                </div>
            </Hydra>
        </>
    );
};

export default HomePage;
