import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchVendors, updateVendor } from '../../features/vendor/vendorSlice';

const Vendors = () => {
    const dispatch = useDispatch();
    const vendors = useSelector((state) => state.vendor.data);  // Get vendors from state
    const status = useSelector((state) => state.vendor.status);
    const error = useSelector((state) => state.vendor.error);

    const [updatedVendors, setUpdatedVendors] = useState({});

    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchVendors());  // Fetch vendors when component mounts
        }
    }, [status, dispatch]);

    const handleInputChange = (e, vendorName) => {
        setUpdatedVendors({
            ...updatedVendors,
            [vendorName]: e.target.value,  // Store input field values
        });
    };

    const handleUpdate = (vendorName) => {
        dispatch(updateVendor({
            vendorName,
            additionalData: updatedVendors[vendorName] || '',  // Dispatch update action
        }));
        alert('Updated.');
    };

    return (
        <div className="vendor-list mt-10">
            <h2 className="text-xl font-semibold text-gray-900 mb-4">Vendors</h2>
            {status === 'loading' && <p>Loading vendors...</p>}
            {status === 'failed' && <p>Error: {error}</p>}
            {status === 'succeeded' && (
                <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Vendor Name</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Additional Data</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {vendors.map((vendor, index) => (
                                <tr key={index}>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-900">{index + 1}. <span className="font-bold">{vendor.name}</span></td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <input
                                            type="text"
                                            value={updatedVendors[vendor.name] || vendor.additionalData || ''}
                                            onChange={(e) => handleInputChange(e, vendor.name)}
                                            className="shadow-sm border py-2 px-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                        />
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <button
                                            onClick={() => handleUpdate(vendor.name)}
                                            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        >
                                            Update
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default Vendors;
